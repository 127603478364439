const paymentErrorTypes = {
  CUSTOMER_MAX_SUBSCRIPTIONS: 'errors.customer_max_subscriptions',
  MISSING_USER_ID_IN_METADATA: 'errors.missing-user-id-in-metadata',
  MISSING_SUBSCRIPTION: 'errors.missing-subscription',
  MISSING_CONTRACT_PRICE: 'errors.missing-contract-price',
  MISSING_FEE_PERCENTAGE: 'errors.missing-fee-percentage',
  SUPPORT_HOURS_PLAN_NOT_FOUND: 'errors.support-hours-plan-not-found',
  PAYMENT_SUBSCRIPTION_NOT_FOUND: 'errors.payment-subscription-not-found',
  ENTERPRISE_SUBSCRIPTION_NOT_FOUND: 'errors.enterprise-subscription-not-found',
  MISSING_ADDITIONAL_SERVICE: 'errors.missing-additional-service',
  GATEWAY_NOT_FOUND: 'errors.gateway-not-found',
  AUTHENTICATION_REQUIRED: 'errors.authentication_required',
  APPROVE_WITH_ID: 'errors.approve_with_id',
  CALL_ISSUER: 'errors.call_issuer',
  CARD_NOT_SUPPORTED: 'errors.card_not_supported',
  CARD_VELOCITY_EXCEEDED: 'errors.card_velocity_exceeded',
  CURRENCY_NOT_SUPPORTED: 'errors.currency_not_supported',
  DO_NOT_HONOR: 'errors.do_not_honor',
  DO_NOT_TRY_AGAIN: 'errors.do_not_try_again',
  DUPLICATE_TRANSACTION: 'errors.duplicate_transaction',
  EXPIRED_CARD: 'errors.expired_card',
  FRAUDULENT: 'errors.fraudulent',
  GENERIC_DECLINE: 'errors.generic_decline',
  INCORRECT_NUMBER: 'errors.incorrect_number',
  INCORRECT_CVC: 'errors.incorrect_cvc',
  INCORRECT_PIN: 'errors.incorrect_pin',
  INCORRECT_ZIP: 'errors.incorrect_zip',
  INSUFFICIENT_FUNDS: 'errors.insufficient_funds',
  INVALID_ACCOUNT: 'errors.invalid_account',
  INVALID_AMOUNT: 'errors.invalid_amount',
  INVALID_CVC: 'errors.invalid_cvc',
  INVALID_EXPIRY_MONTH: 'errors.invalid_expiry_month',
  INVALID_EXPIRY_YEAR: 'errors.invalid_expiry_year',
  INVALID_NUMBER: 'errors.invalid_number',
  INVALID_PIN: 'errors.invalid_pin',
  ISSUES_NOT_AVAILABLE: 'errors.issuer_not_available',
  LOST_CARD: 'errors.lost_card',
  MERCHANT_BLACKLIST: 'errors.merchant_blacklist',
  NEW_ACCOUNT_INFORMATION_AVAILABLE: 'errors.new_account_information_available',
  NO_ACTION_TAKEN: 'errors.no_action_taken',
  NOT_PERMITTED: 'errors.not_permitted',
  OFFLINE_PIN_REQUIRED: 'errors.offline_pin_required',
  ONLINE_OR_OFFLINE_PIN_REQUIRED: 'errors.online_or_offline_pin_required',
  PICKUP_CARD: 'errors.pickup_card',
  PIN_TRY_EXCEEDED: 'errors.pin_try_exceeded',
  PROCESSING_ERROR: 'errors.processing_error',
  REENTER_TRANSACTION: 'errors.reenter_transaction',
  RESTRICTED_CARD: 'errors.restricted_card',
  REVOCATION_OF_ALL_AUTHORIZATIONS: 'errors.revocation_of_all_authorizations',
  REVOCATION_OF_AUTHORIZATION: 'errors.revocation_of_authorization',
  SECURITY_VIOLATION: 'errors.security_violation',
  SERVICE_NOT_ALLOWED: 'errors.service_not_allowed',
  STOLEN_CARD: 'errors.stolen_card',
  STOP_PAYMENT_ORDER: 'errors.stop_payment_order',
  TESTMODE_DECLINE: 'errors.testmode_decline',
  TRANSACTION_NOT_ALLOWED: 'errors.transaction_not_allowed',
  TRY_AGAIN_LATER: 'errors.try_again_later',
  WITHDRAWAL_COUNT_LIMIT_EXCEEDED: 'errors.withdrawal_count_limit_exceeded',
  RESOURCE_MISSING: 'errors.resource_missing',
  CARD_DECLINED: 'errors.card_declined',
  SUBSCRIPTION_PAYMENT_INTENT_REQUIRES_ACTION:
    'errors.subscription_payment_intent_requires_action',
  PUBLISHED_SOLUTIONS_LIMIT_REACHED: 'errors.published-solutions-limit-reached',
  PUBLISHED_SOLUTIONS_LIMIT_REACHED_FOR_ADMINS:
    'errors.published-solutions-limit-reached-for-admins',
  SUBSCRIPTION_NOT_FOUND: 'errors.subscription-not-found',
  PENDING_INVOICES: 'errors.pending-invoices',
  INVOICE_NOT_FOUND: 'errors.invoice-not-found',
  INVOICE_PAYMENT_INTENT_REQUIRES_ACTION:
    'errors.invoice_payment_intent_requires_action',
  INVALID_VALUE: 'errors.invalid-value',
} as const

const paymentErrorMessages = {
  [paymentErrorTypes.CUSTOMER_MAX_SUBSCRIPTIONS]:
    'Customer has reached the maximum number (3) of scheduled subscriptions in development mode',
  [paymentErrorTypes.MISSING_USER_ID_IN_METADATA]:
    'User ID is missing in the subscription metadata on Stripe',
  [paymentErrorTypes.MISSING_SUBSCRIPTION]:
    'Subscription not found in the database',
  [paymentErrorTypes.MISSING_CONTRACT_PRICE]:
    'Contract price metadata not found',
  [paymentErrorTypes.MISSING_FEE_PERCENTAGE]:
    'Fee percentage metadata not found',
  [paymentErrorTypes.SUPPORT_HOURS_PLAN_NOT_FOUND]:
    'Support Hours plan not found',
  [paymentErrorTypes.PAYMENT_SUBSCRIPTION_NOT_FOUND]:
    'Payment subscription not found',
  [paymentErrorTypes.ENTERPRISE_SUBSCRIPTION_NOT_FOUND]:
    'Enterprise subscription not found',
  [paymentErrorTypes.MISSING_ADDITIONAL_SERVICE]:
    'Missing additional service in request',
  [paymentErrorTypes.GATEWAY_NOT_FOUND]:
    "Payment gateway not found, please contact the administrator's support",
  [paymentErrorTypes.AUTHENTICATION_REQUIRED]:
    'The card was declined as the transaction requires authentication.',
  [paymentErrorTypes.APPROVE_WITH_ID]:
    'The card was declined as the transaction requires authentication with a cardholder ID.',
  [paymentErrorTypes.CALL_ISSUER]:
    'The card was declined as the transaction requires authentication with a cardholder ID.',
  [paymentErrorTypes.CARD_NOT_SUPPORTED]:
    'The card was declined as it is not supported by the payment processor.',
  [paymentErrorTypes.CARD_VELOCITY_EXCEEDED]:
    'The card was declined as it has been used too many times in a short period of time.',
  [paymentErrorTypes.CURRENCY_NOT_SUPPORTED]:
    'The card was declined as the transaction currency is not supported by the payment processor.',
  [paymentErrorTypes.DO_NOT_HONOR]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.DO_NOT_TRY_AGAIN]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.DUPLICATE_TRANSACTION]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.EXPIRED_CARD]: 'The card has expired.',
  [paymentErrorTypes.FRAUDULENT]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.GENERIC_DECLINE]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.INCORRECT_NUMBER]: 'The card number is incorrect.',
  [paymentErrorTypes.INCORRECT_CVC]: "The card's security code is incorrect.",
  [paymentErrorTypes.INCORRECT_PIN]: "The card's PIN is incorrect.",
  [paymentErrorTypes.INCORRECT_ZIP]: "The card's zip code failed validation.",
  [paymentErrorTypes.INSUFFICIENT_FUNDS]:
    'The card has insufficient funds to complete the transaction.',
  [paymentErrorTypes.INVALID_ACCOUNT]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.INVALID_AMOUNT]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.INVALID_CVC]: "The card's security code is invalid.",
  [paymentErrorTypes.INVALID_EXPIRY_MONTH]:
    "The card's expiration month is invalid.",
  [paymentErrorTypes.INVALID_EXPIRY_YEAR]:
    "The card's expiration year is invalid.",
  [paymentErrorTypes.INVALID_NUMBER]: 'The card number is invalid.',
  [paymentErrorTypes.INVALID_PIN]: "The card's PIN is invalid.",
  [paymentErrorTypes.ISSUES_NOT_AVAILABLE]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.LOST_CARD]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.MERCHANT_BLACKLIST]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.NEW_ACCOUNT_INFORMATION_AVAILABLE]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.NO_ACTION_TAKEN]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.NOT_PERMITTED]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.OFFLINE_PIN_REQUIRED]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.ONLINE_OR_OFFLINE_PIN_REQUIRED]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.PICKUP_CARD]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.PIN_TRY_EXCEEDED]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.PROCESSING_ERROR]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.REENTER_TRANSACTION]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.RESTRICTED_CARD]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.REVOCATION_OF_ALL_AUTHORIZATIONS]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.REVOCATION_OF_AUTHORIZATION]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.SECURITY_VIOLATION]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.SERVICE_NOT_ALLOWED]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.STOLEN_CARD]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.STOP_PAYMENT_ORDER]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.TESTMODE_DECLINE]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.TRANSACTION_NOT_ALLOWED]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.TRY_AGAIN_LATER]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.WITHDRAWAL_COUNT_LIMIT_EXCEEDED]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.RESOURCE_MISSING]:
    'The payment processor could not be reached.',
  [paymentErrorTypes.CARD_DECLINED]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
  [paymentErrorTypes.SUBSCRIPTION_PAYMENT_INTENT_REQUIRES_ACTION]:
    'The card was declined as the transaction was declined by the payment processor without additional information.',
} as const

const incentiveErrorTypes = {
  INCENTIVE_NOT_FOUND: 'errors.incentive-not-found',
  INCENTIVE_INSUFFICIENT_FUNDS: 'errors.incentive-insufficient-funds',
  INVALID_INCENTIVE_AMOUNT: 'errors.invalid-amount',
  INVALID_INCENTIVE_TYPE: 'errors.invalid-type',
  INVALID_INCENTIVE_ORIGIN: 'errors.invalid-origin',
  INCENTIVE_EXPIRED: 'errors.incentive-expired',
  INCENTIVE_CANCELED: 'errors.incentive-canceled',
  INCENTIVE_ALREADY_CLAIMED: 'errors.incentive-already-claimed',
  INCENTIVE_ALREADY_LINKED: 'errors.incentive-already-linked',
  INCENTIVE_NOT_APPROVED: 'errors.incentive-not-approved',
  USER_NOT_LINKED_WITH_INCENTIVE: 'errors.user-not-linked-with-incentive',
  INCENTIVE_ALREADY_REDEEMED: 'errors.incentive-already-redeemed',
  CANNOT_CANCEL_INCENTIVE_AFTER_7_DAYS_OF_CLAIM:
    'errors.cannot-cancel-incentive-after-7-days-of-claim',
  INCENTIVE_NOT_PENDING: 'errors.incentive-not-pending',
  INCENTIVE_NOT_LINKED: 'errors.incentive-not-linked',
} as const

const incentiveErrorMessages = {
  [incentiveErrorTypes.INCENTIVE_NOT_FOUND]: 'Incentive not found',
  [incentiveErrorTypes.INCENTIVE_INSUFFICIENT_FUNDS]:
    'Not enough funds to approve incentives',
  [incentiveErrorTypes.INVALID_INCENTIVE_AMOUNT]:
    'Amount not in the list of valid amounts',
  [incentiveErrorTypes.INVALID_INCENTIVE_TYPE]: 'Invalid incentive type',
  [incentiveErrorTypes.INVALID_INCENTIVE_ORIGIN]: 'Invalid incentive origin',
  [incentiveErrorTypes.INCENTIVE_EXPIRED]: 'Incentive has expired',
  [incentiveErrorTypes.INCENTIVE_CANCELED]: 'Incentive has been canceled',
  [incentiveErrorTypes.INCENTIVE_ALREADY_CLAIMED]: 'Incentive already claimed',
  [incentiveErrorTypes.INCENTIVE_ALREADY_LINKED]:
    'Incentive already linked to a user',
  [incentiveErrorTypes.INCENTIVE_NOT_APPROVED]: 'Incentive not yet approved',
  [incentiveErrorTypes.USER_NOT_LINKED_WITH_INCENTIVE]:
    'User not linked to the incentive invitation',
  [incentiveErrorTypes.INCENTIVE_ALREADY_REDEEMED]:
    'Incentive already redeemed',
  [incentiveErrorTypes.CANNOT_CANCEL_INCENTIVE_AFTER_7_DAYS_OF_CLAIM]:
    'Cancellation is no longer possible. It could only be canceled within 7 days after claiming.',
  [incentiveErrorTypes.INCENTIVE_NOT_PENDING]: 'Incentive is not pending',
  [incentiveErrorTypes.INCENTIVE_NOT_LINKED]: 'Incentive is not linked',
} as const

export const errorTypes = {
  ...paymentErrorTypes,
  ...incentiveErrorTypes,
  NOT_ADMIN_ACCOUNT: 'errors.not-admin-account',
  NOT_VENDOR_ACCOUNT: 'errors.not-vendor-account',
  NOT_BUYER_ACCOUNT: 'errors.not-buyer-account',
  BUYER_EXTERNAL_ID_ALREADY_EXISTS: 'errors.buyer-external-id-already-exists',
  MAIN_CATEGORY_ALREADY_EXISTS: 'errors.main-category-already-exists',
  SUB_CATEGORY_ALREADY_EXISTS: 'errors.sub-category-already-exists',
  VERTICAL_EXPERTISE_ALREADY_EXISTS: 'errors.vertical-expertise-already-exists',
  BRAND_ALREADY_EXISTS: 'errors.brand-already-exists',
  BRAND_ALREADY_FAVORITE: 'errors.brand-already-favorite',
  MAIN_CATEGORY_IN_USE: 'errors.main-category-in-use',
  GEO_IN_USE: 'errors.geo-in-use',
  SUB_CATEGORY_IN_USE: 'errors.sub-category-in-use',
  BRAND_IN_USE: 'errors.brand-in-use',
  VERTICAL_EXPERTISE_IN_USE: 'errors.vertical-expertise-in-use',
  MAIN_CATEGORIES_NOT_FOUND: 'errors.main-categories-not-found',
  VERTICAL_EXPERTISE_NOT_FOUND: 'errors.vertical-expertise-not-found',
  SUB_CATEGORIES_NOT_FOUND: 'errors.sub-categories-not-found',
  CATEGORY_V2_NOT_FOUND: 'errors.category-v2-not-found',
  CATEGORY_FEATURE_V2_NOT_FOUND: 'errors.category-feature-v2-not-found',
  CATEGORY_FILTER_V2_NOT_FOUND: 'errors.category-filter-v2-not-found',
  GEO_NOT_FOUND: 'errors.geo-not-found',
  BRAND_NOT_FOUND: 'errors.brand-not-found',
  PRESS_POST_NOT_FOUND: 'errors.press-post-not-found',
  BLOG_POST_NOT_FOUND: 'errors.blog-post-not-found',
  VENDORS_NOT_FOUND: 'errors.vendors-not-found',
  VENDOR_EXTERNAL_ID_ALREADY_EXISTS: 'errors.vendor-external-id-already-exists',
  INVALID_CATEGORY_FEATURE_V2_ID: 'errors.invalid-category-feature-v2-id',
  INVALID_GEO_ID: 'errors.invalid-geo-id',
  INVALID_CATEGORY_FILTER_V2_ID: 'errors.invalid-category-filter-v2-id',
  INVALID_CATEGORY_V2_ID: 'errors.invalid-category-v2-id',
  INVALID_VENDOR_ID: 'errors.invalid-vendor-id',
  INVALID_SOLUTION_ID: 'errors.invalid-solution-id',
  INVALID_WHITEPAPER_ID: 'errors.invalid-whitepaper-id',
  INVALID_PRODUCT_SERVICE_ID: 'errors.invalid-product-service-id',
  INVALID_PLAN_ID: 'errors.invalid-plan-id',
  INVALID_COUPON_ID: 'errors.invalid-coupon-id',
  INVALID_PRESS_POST_ID: 'errors.invalid-press-post-id',
  INVALID_BLOG_POST_ID: 'errors.invalid-blog-post-id',
  INVALID_LEAD_ID: 'errors.invalid-lead-id',
  INVALID_SUBSCRIPTION_ID: 'errors.invalid-subscription-id',
  BUYER_INVALID_CHATBOT_CONVERSATION_ID:
    'errors.invalid-buyer-chatbot-conversation-id',
  BUYER_INVALID_CHATBOT_MESSAGE_ID: 'errors.invalid-buyer-chatbot-message-id',
  BUYER_CHATBOT_CONVERSATION_NOT_FOUND:
    'errors.buyer-chatbot-conversation-not-found',
  BUYER_CHATBOT_MESSAGE_ALREADY_REPORTED:
    'errors.buyer-chatbot-message-already-reported',
  INVALID_COUPON_DURATION: 'errors.invalid-coupon-duration',
  COMPANIES_NOT_FOUND: 'errors.companies-not-found',
  INVALID_CODE: 'errors.invalid-code',
  HAS_ADMIN_ACCOUNT_CODE: 'error.has-admin-account',
  NOT_FOUND: 'errors.not-found',
  BUYER_NOT_FOUND: 'errors.buyer-not-found',
  LEGACY_BUYER_ACCOUNT_ALREADY_CREATED:
    'errors.legacy-buyer-account-already-created',
  BUYER_ALREADY_DELETED: 'errors.buyer-already-deleted',
  MAIN_CATEGORY_NOT_FOUND: 'errors.main-category-not-found',
  SUB_CATEGORY_NOT_FOUND: 'errors.sub-category-not-found',
  VENDOR_NOT_FOUND: 'errors.vendor-not-found',
  VENDOR_NOT_ACTIVE: 'errors.vendor-not-active',
  LEGACY_VENDOR_ACCOUNT_ALREADY_CREATED:
    'errors.legacy-vendor-account-already-created',
  VENDOR_ALREADY_DELETED: 'errors.vendor-already-deleted',
  ADMIN_NOT_FOUND: 'errors.admin-not-found',
  COMPANY_NOT_FOUND: 'errors.company-not-found',
  COMPANY_NOT_INFORMED: 'errors.company-not-informed',
  WHITEPAPER_NOT_FOUND: 'errors.whitepaper-not-found',
  WHITEPAPER_ANALYTICS_CANNOT_BE_VOID:
    'errors.whitepaper-analytics-cannot-be-void',
  VIDEO_ANALYTICS_CANNOT_BE_VOID: 'errors.video-analytics-cannot-be-void',
  VIDEO_NOT_FOUND: 'errors.video-not-found',
  INVALID_IMAGE_TYPE: 'errors.invalid-image-type',
  INVALID_PDF_TYPE: 'errors.invalid-pdf-type',
  INVALID_VIDEO_TYPE: 'errors.invalid-video-type',
  FILE_SIZE_TOO_LARGE: 'errors.file-size-too-large',
  USER_EMAIL_NOT_CONFIRMED: 'errors.user-email-not-confirmed',
  USER_WITH_TEMPORARY_PASSWORD: 'errors.user-with-temporary-password',
  INVALID_NEW_PASSWORD_EQUALS_CURRENT:
    'errors.invalid-new-password-equals-current',
  INVALID_PASSWORD: 'errors.invalid-password',
  ALREADY_EXISTS: 'errors.already-exists',
  PRIVATE_PRODUCT_SERVICE_WITHOUT_COMPANIES:
    'errors.private-product-service-without-companies',
  PRODUCT_SERVICE_INCOMPLETE: 'errors.product-service-incomplete',
  CANT_ARCHIVE_LAST_PRODUCT_SERVICE_IN_USE_BY_PUBLISHED_SOLUTION:
    'errors.cant-archive-only-product-service-in-use-by-published-solution',
  CANT_DRAFT_LAST_PRODUCT_SERVICE_IN_USE_BY_PUBLISHED_SOLUTION:
    'errors.cant-draft-only-product-service-in-use-by-published-solution',
  CANT_DELETE_NOT_ARCHIVED_PRODUCT_SERVICE:
    'errors.cant-delete-not-archived-product-service',
  CANT_DELETE_PRODUCT_SERVICE_NOT_ARCHIVED:
    'errors.cant-delete-product-service-not-archived',
  BUYER_SOLUTION_SEARCH_NAME_ALREADY_EXISTS:
    'errors.buyer-solution-search-name-already-exists',
  BUYER_SOLUTION_SAVED_SEARCH_NOT_FOUND:
    'errors.buyer-solution-saved-search-not-found',
  USER_IS_INACTIVE: 'errors.user-is-inactive',
  USER_DISABLED: 'errors.user-disabled',
  USER_NOT_FOUND: 'errors.user-not-found',
  FORBIDDEN_ACTION_WHEN_IMPERSONATING:
    'errors.forbidden-action-when-impersonating',
  CANNOT_UPDATE_OWN_STATUS: 'errors.cannot-update-own-status',
  CANNOT_UPDATE_ITSELF: 'errors.cannot-update-itself',
  CANNOT_DELETE_ITSELF: 'errors.cannot-delete-itself',
  PRODUCT_SERVICE_NOT_FOUND: 'errors.product-service-not-found',
  PRODUCTS_SERVICES_NOT_FOUND: 'errors.products-services-not-found',
  DONT_HAVE_ACCESS: 'errors.dont-have-access',
  MARKETS_NOT_FOUND: 'errors.markets-not-found',
  GEOS_NOT_FOUND: 'errors.geos-not-found',
  DISTRIBUTORS_NOT_FOUND: 'errors.distributors-not-found',
  BRANDS_NOT_FOUND: 'errors.brands-not-found',
  MUST_HAVE_AT_LEAST_ONE_PRODUCT_SERVICE:
    'errors.must-have-at-least-one-product-service',
  USER_NOT_AUTHORIZED: 'errors.user-not-authorized',
  SOLUTION_NOT_FOUND: 'errors.solution-not-found',
  SOLUTION_V2_ENABLED: 'errors.solution-v2-enabled',
  SOLUTION_V2_DISABLED: 'errors.solution-v2-disabled',
  MULTIPLE_SOLUTIONS_FOUND_WITH_GIVEN_NAME:
    'errors.multiple-solutions-found-with-given-name',
  SOLUTIONS_NOT_FOUND: 'errors.solutions-not-found',
  SOLUTION_INCOMPLETE: 'errors.solution-incomplete',
  SOLUTION_EXTERNAL_ID_ALREADY_EXISTS:
    'errors.solution-external-id-already-exists',
  INVALID_RANGE_VALUE: 'errors.invalid-range-value',
  CANT_DELETE_SOLUTION_NOT_ARCHIVED: 'errors.cant-delete-solution-not-archived',
  SOLUTION_WITHOUT_PUBLISHED_PRODUCTS_SERVICES:
    'errors.solution-without-published-products-services',
  SOLUTION_MATCH_ALREADY_ACCEPTED: 'errors.solution-matched-already-accepted',
  SOLUTION_MATCH_ALREADY_CANCELED: 'errors.solution-matched-already-canceled',
  OPPORTUNITY_PURCHASE_TIMEFRAME_NOT_FOUND:
    'errors.opportunity-purchase-timeframe-not-found',
  OPPORTUNITY_EVALUATION_TIMEFRAME_NOT_FOUND:
    'errors.opportunity-evaluation-timeframe-not-found',
  OPPORTUNITY_ROLE_NOT_FOUND: 'errors.opportunity-role-not-found',
  OPPORTUNITY_NOT_FOUND: 'errors.opportunity-not-found',
  OPPORTUNITY_ALREADY_EXISTS: 'errors.opportunity-already-exists',
  CHAT_NOT_FOUND: 'errors.chat-not-found',
  CHAT_ALREADY_ARCHIVED: 'errors.chat-already-archived',
  CHAT_NOT_ARCHIVED: 'errors.chat-not-archived',
  CHAT_CHANNEL_NOT_FOUND: 'errors.chat-channel-not-found',
  SOLUTION_ALREADY_FAVORITE: 'errors.solution-already-favorite',
  CANT_CHAT_WITH_YOURSELF: 'errors.cant-chat-with-yourself',
  LEAD_NOT_FOUND: 'errors.lead-not-found',
  FAILED_CONNECT_CHAT: 'errors.failed-connect-chat',
  CANNOT_HIDE_LEAD_WITH_NOT_LOST_OR_DEAD_OPPORTUNITIES:
    'errors.cannot-hide-lead-with-not-lost-or-dead-opportunities',
  OPPORTUNITY_CANNOT_BE_UPDATED: 'errors.opportunity-cannot-be-updated',
  BUYER_BATCH_UPDATE_MAX_SIZE: 'errors.buyer-batch-update-max-size',
  VENDOR_BATCH_UPDATE_MAX_SIZE: 'errors.vendor-batch-update-max-size',
  ADMIN_BATCH_UPDATE_MAX_SIZE: 'errors.admin-batch-update-max-size',
  SOLUTION_BATCH_UPDATE_MAX_SIZE: 'errors.solution-batch-update-max-size',
  BUYER_BATCH_DELETE_MAX_SIZE: 'errors.buyer-batch-delete-max-size',
  VENDOR_BATCH_DELETE_MAX_SIZE: 'errors.vendor-batch-delete-max-size',
  ADMIN_BATCH_DELETE_MAX_SIZE: 'errors.admin-batch-delete-max-size',
  VERTICAL_EXPERTISE_BATCH_UPDATE_MAX_SIZE:
    'errors.vertical_expertise_batch_update_max_size',
  VERTICAL_EXPERTISE_BATCH_DELETE_MAX_SIZE:
    'errors.vertical_expertise_batch_delete_max_size',
  MAIN_CATEGORY_BATCH_UPDATE_MAX_SIZE:
    'errors.main-category-batch-update-max-size',
  MAIN_CATEGORY_BATCH_DELETE_MAX_SIZE:
    'errors.main-category-batch-delete-max-size',
  SUB_CATEGORY_BATCH_UPDATE_MAX_SIZE:
    'errors.sub-category-batch-update-max-size',
  SUB_CATEGORY_BATCH_DELETE_MAX_SIZE:
    'errors.sub-category-batch-delete-max-size',
  PRODUCT_SERVICE_BATCH_UPDATE_MAX_SIZE:
    'errors.product-service-batch-update-max-size',
  PRODUCT_SERVICE_BATCH_DELETE_MAX_SIZE:
    'errors.product-service-batch-delete-max-size',
  GEO_BATCH_UPDATE_MAX_SIZE: 'errors.geo-batch-update-max-size',
  GEO_BATCH_DELETE_MAX_SIZE: 'errors.geo-batch-delete-max-size',
  CHAT_BUYER_MISMATCH: 'errors.chat-buyer-mismatch',
  CHAT_VENDOR_COMPANY_MISMATCH: 'errors.chat-vendor-company-mismatch',
  MAX_LENGTH: 'errors.max-length',
  WRONG_FORMAT: 'errors.wrong-format',
  PLAN_NOT_FOUND: 'errors.plan-not-found',
  STRIPE_ID_NOT_FOUND: 'errors.stripe-id-not-found',
  INVOICE_PDF_GENERATION: 'errors.invoice-pdf-generation',
  INTERNAL_SERVER_ERROR: 'errors.internal-server-error',
  TOO_WEAK: 'errors.too-weak',
  MIN: 'errors.min',
  MAX_VALUE: 'errors.max-value',
  MIN_LENGTH: 'errors.min-length',
  MAX_DATE: 'errors.max-date',
  LIMIT_EXCEPTION: 'errors.limit-exception',
  PAYMENT_PLAN_PRICING_NOT_FOUND: 'errors.payment-plan-pricing-not-found',
  SUPPORT_HOURS_PRICE_NOT_FOUND: 'errors.support-hours-price-not-found',
  PRICES_NOT_FOUND: 'errors.prices-not-found',
  MISSING_AT_LEAST_ONE_PLAN_AVAILABLE:
    'errors.missing-at-least-one-plan-available',
  HIGHLIGHTED_PLAN_NOT_AVAILABLE: 'errors.highlighted-plan-not-available',
  ALREADY_SUBSCRIBED_TO_A_PLAN: 'errors.already-subscribed-to-a-plan',
  PLAN_NOT_AVAILABLE: 'errors.plan-not-available',
  SUPPORT_HOUR_NOT_FOUND: 'errors.support-hour-not-found',
  CANNOT_APPROVE_SUPPORT_HOUR: 'errors.cannot-approve-support-hour',
  CANNOT_REJECT_SUPPORT_HOUR: 'errors.cannot-reject-support-hour',
  CANNOT_DELETE_SUPPORT_HOUR: 'errors.cannot-delete-support-hour',
  CANNOT_UPDATE_SUPPORT_HOUR_RATE: 'errors.cannot-update-support-hour-rate',
  EMAIL_CANNOT_BE_SAME_AS_PERSONAL_EMAIL:
    'errors.email-cannot-be-same-as-personal-email',
  CANNOT_PERFORM_ACTION_ON_SUPPORT_HOUR:
    'errors.cannot-perform-action-on-support-hour',
  VENDOR_SUBSCRIPTION_BLOCKING:
    'errors.vendor-subscription-blocking-solution-publish',
  NO_CSV_DATA: 'errors.no-csv-data',
  FEATURE_NOT_AVAILABLE: 'errors.feature-not-available',
  COUPON_NOT_FOUND: 'errors.coupon-not-found',
  BLOG_WITH_TITLE_ALREADY_EXISTS: 'errors.blog-with-title-already-exists',
  WHITEPAPER_WITH_TITLE_ALREADY_EXISTS:
    'errors.whitepaper-with-title-already-exists',
  BLOG_POST_INCOMPLETE: 'errors.blog-post-incomplete',
  PRESS_POST_INCOMPLETE: 'errors.press-post-incomplete',
  WHITEPAPER_INCOMPLETE: 'errors.whitepaper-incomplete',
  VENDOR_CAMPAIGN_INCOMPLETE: 'errors.vendor-campaign-incomplete',
  PROMOTION_CODE_NOT_FOUND: 'errors.promotion-code-not-found',
  PROMOTION_CODE_EXPIRED: 'errors.promotion-code-expired',
  PROMOTION_CODE_INACTIVE: 'errors.promotion-code-inactive',
  CALENDAR_EVENT_NOT_FOUND: 'errors.calendar-event-not-found',
  PAST_DATE_NOT_ALLOWED: 'errors.past-date-not-allowed',
  MAX_ONE_YEAR_IN_FUTURE: 'errors.max-one-year-in-future',
  CALENDAR_SLOT_UNAVAILABLE: 'errors.calendar-slot-unavailable',
  INVALID_CALENDAR_EVENT_CALL_TYPE: 'errors.invalid-calendar-event-call-type',
  VENDOR_CALENDAR_NO_LONGER_CONNECTED:
    'errors.vendor-calendar-no-longer-connected',
  MAX_SOLUTIONS: 'errors.max-solutions',
  INVALID_OFFER_ID: 'errors.invalid-offer-id',
  INVALID_PARTNER_ID: 'errors.invalid-partner-id',
  OFFER_NOT_FOUND: 'errors.offer-not-found',
  OFFER_NOT_ACTIVE: 'errors.offer-not-active',
  PARTNER_NOT_FOUND: 'errors.partner-not-found',
  PARTNER_NOT_ACTIVE: 'errors.partner-not-active',
  STRIPE_CUSTOMER_NOT_FOUND: 'errors.stripe-customer-not-found',
  MAX_STANDARD_PLANS_REACHED: 'errors.max-standard-plans-reached',
  INVALID_SUPPORT_HOUR_ID: 'errors.invalid-support-hour-id',
  VENDOR_CAMPAIGN_NOT_FOUND: 'errors.vendor-campaign-not-found',
  INVALID_VENDOR_CAMPAIGN_ID: 'errors.invalid-vendor-campaign-id',
  FINANCE_CONTACT_CANNOT_BE_REMOVED: 'errors.finance-contact-cannot-be-removed',
  DEFAULT: 'DEFAULT',
} as const

export const errorMessages: {
  [key in (typeof errorTypes)[keyof typeof errorTypes]]: string
} = {
  ...paymentErrorMessages,
  ...incentiveErrorMessages,
  [errorTypes.ALREADY_EXISTS]: 'Email already registered',
  [errorTypes.INTERNAL_SERVER_ERROR]: 'Internal server error',
  [errorTypes.TOO_WEAK]: 'Password is too weak',
  [errorTypes.MIN_LENGTH]: 'Minimum length is',
  [errorTypes.MAX_LENGTH]: 'Maximum length is',
  [errorTypes.MIN]: 'Minimum value is',
  [errorTypes.MAX_VALUE]: 'Maximum value is',
  [errorTypes.MAX_DATE]: 'Maximum date is',
  [errorTypes.WRONG_FORMAT]: 'Wrong format',
  [errorTypes.LIMIT_EXCEPTION]: 'Request limit reached',
  [errorTypes.INVALID_CODE]: 'Invalid code',
  [errorTypes.NOT_ADMIN_ACCOUNT]: 'The informed account is not an admin',
  [errorTypes.NOT_VENDOR_ACCOUNT]: 'The informed account is not a vendor',
  [errorTypes.NOT_BUYER_ACCOUNT]: 'The informed account is not a customer',
  [errorTypes.BUYER_EXTERNAL_ID_ALREADY_EXISTS]:
    'Buyer External ID already exists',
  [errorTypes.VENDOR_EXTERNAL_ID_ALREADY_EXISTS]:
    'Vendor External ID already exists',
  [errorTypes.MAIN_CATEGORY_ALREADY_EXISTS]:
    'This Main Category already exists',
  [errorTypes.SUB_CATEGORY_ALREADY_EXISTS]: 'This Sub Category already exists',
  [errorTypes.VERTICAL_EXPERTISE_ALREADY_EXISTS]:
    'This Vertical Expertise already exists',
  [errorTypes.BRAND_ALREADY_EXISTS]: 'This brand already exists',
  [errorTypes.BRAND_ALREADY_FAVORITE]: 'Brand already favorite',
  [errorTypes.MAIN_CATEGORY_IN_USE]:
    "This Main Category is being used and can't be deleted",
  [errorTypes.GEO_IN_USE]: "This Geo is being used and can't be deleted",
  [errorTypes.SUB_CATEGORY_IN_USE]:
    "This Sub Category is being used and can't be deleted",
  [errorTypes.BRAND_IN_USE]: "This Brand is being used and can't be deleted",
  [errorTypes.VERTICAL_EXPERTISE_IN_USE]:
    "This Vertical Expertise is being used and can't be deleted",
  [errorTypes.MAIN_CATEGORIES_NOT_FOUND]: 'Main Categories not found',
  [errorTypes.VERTICAL_EXPERTISE_NOT_FOUND]: 'Vertical Expertise not found',
  [errorTypes.SUB_CATEGORIES_NOT_FOUND]: 'Sub categories not found',
  [errorTypes.GEO_NOT_FOUND]: 'Geo not found',
  [errorTypes.BRAND_NOT_FOUND]: 'Brand not found',
  [errorTypes.PRESS_POST_NOT_FOUND]: 'Press post not found',
  [errorTypes.BLOG_POST_NOT_FOUND]: 'Blog post not found',
  [errorTypes.VENDORS_NOT_FOUND]: 'Vendors not found',
  [errorTypes.INVALID_VENDOR_ID]: 'Invalid Vendor ID',
  [errorTypes.COMPANIES_NOT_FOUND]: 'Companies not found',
  [errorTypes.HAS_ADMIN_ACCOUNT_CODE]:
    'You have an administrator account. Please log in through the admin panel',
  [errorTypes.NOT_FOUND]: 'Not found',
  [errorTypes.BUYER_NOT_FOUND]: 'Buyer not found',
  [errorTypes.LEGACY_BUYER_ACCOUNT_ALREADY_CREATED]:
    'Tech Customer account already created',
  [errorTypes.BUYER_ALREADY_DELETED]: 'Buyer already deleted',
  [errorTypes.MAIN_CATEGORY_NOT_FOUND]: 'Main Category not found',
  [errorTypes.SUB_CATEGORY_NOT_FOUND]: 'Sub Category not found',
  [errorTypes.VENDOR_NOT_FOUND]: 'Vendor not found',
  [errorTypes.VENDOR_NOT_ACTIVE]: 'Vendor not active',
  [errorTypes.LEGACY_VENDOR_ACCOUNT_ALREADY_CREATED]:
    'Tech Vendor account already created',
  [errorTypes.VENDOR_ALREADY_DELETED]: 'Vendor already deleted',
  [errorTypes.ADMIN_NOT_FOUND]: 'Admin not found',
  [errorTypes.WHITEPAPER_NOT_FOUND]: 'White Paper Not found',
  [errorTypes.WHITEPAPER_ANALYTICS_CANNOT_BE_VOID]:
    'White Paper Analytics action cannot be void',
  [errorTypes.VIDEO_ANALYTICS_CANNOT_BE_VOID]:
    'Video Analytics action cannot be void',
  [errorTypes.VIDEO_NOT_FOUND]: 'Video not found',
  [errorTypes.INVALID_PDF_TYPE]: 'Invalid PDF type',
  [errorTypes.INVALID_VIDEO_TYPE]:
    'Invalid video type, only MP4 and MOV are allowed',
  [errorTypes.FILE_SIZE_TOO_LARGE]: 'File too large, maximum is',
  [errorTypes.INVALID_IMAGE_TYPE]:
    'Invalid image type, only JPG, PNG and JFIF are allowed',
  [errorTypes.COMPANY_NOT_FOUND]: 'Company not found',
  [errorTypes.COMPANY_NOT_INFORMED]: 'Company not informed',
  [errorTypes.USER_NOT_FOUND]: 'User not found',
  [errorTypes.FORBIDDEN_ACTION_WHEN_IMPERSONATING]:
    "Can't perform this action when impersonating",
  [errorTypes.USER_DISABLED]: 'User disabled',
  [errorTypes.USER_EMAIL_NOT_CONFIRMED]: 'User email not confirmed',
  [errorTypes.USER_WITH_TEMPORARY_PASSWORD]: 'User with temporary password',
  [errorTypes.INVALID_PASSWORD]: 'Invalid password',
  [errorTypes.INVALID_NEW_PASSWORD_EQUALS_CURRENT]:
    "Your new password can't be the same as your current one",
  [errorTypes.PRIVATE_PRODUCT_SERVICE_WITHOUT_COMPANIES]:
    'Private product/service without vendors',
  [errorTypes.PRODUCT_SERVICE_INCOMPLETE]: 'Product/service incomplete',
  [errorTypes.CANT_DRAFT_LAST_PRODUCT_SERVICE_IN_USE_BY_PUBLISHED_SOLUTION]:
    "Can't draft last Product/Service being used by published Solution",
  [errorTypes.CANT_ARCHIVE_LAST_PRODUCT_SERVICE_IN_USE_BY_PUBLISHED_SOLUTION]:
    "Can't archive last Product/Service being used by published Solution",
  [errorTypes.CANT_DELETE_NOT_ARCHIVED_PRODUCT_SERVICE]:
    "Can't delete not archived Product/Service",
  [errorTypes.CANT_DELETE_PRODUCT_SERVICE_NOT_ARCHIVED]:
    "Can't delete Product/Service not archived",
  [errorTypes.USER_IS_INACTIVE]: 'Your account is inactive',
  [errorTypes.CANNOT_UPDATE_OWN_STATUS]: 'You cannot update your own status',
  [errorTypes.CANNOT_DELETE_ITSELF]: 'You cannot delete your own account',
  [errorTypes.CANNOT_UPDATE_ITSELF]: 'You cannot update your own account',
  [errorTypes.PRODUCT_SERVICE_NOT_FOUND]: 'Product/Service not found',
  [errorTypes.PRODUCTS_SERVICES_NOT_FOUND]: 'Products/Services not found',
  [errorTypes.DONT_HAVE_ACCESS]: "You don't have access to that page",
  [errorTypes.MARKETS_NOT_FOUND]: 'Markets not found',
  [errorTypes.GEOS_NOT_FOUND]: 'Geos not found',
  [errorTypes.DISTRIBUTORS_NOT_FOUND]: 'Distributors not found',
  [errorTypes.BRANDS_NOT_FOUND]: 'Brands not found',
  [errorTypes.MUST_HAVE_AT_LEAST_ONE_PRODUCT_SERVICE]:
    "You can't have a solution without any Product/Service.",
  [errorTypes.USER_NOT_AUTHORIZED]:
    'You are not authorized to perform this action',
  [errorTypes.SOLUTION_NOT_FOUND]: 'Solution not found',
  [errorTypes.SOLUTION_V2_ENABLED]: 'Solution V2 enabled',
  [errorTypes.SOLUTION_V2_DISABLED]: 'Solution V2 disabled',
  [errorTypes.MULTIPLE_SOLUTIONS_FOUND_WITH_GIVEN_NAME]:
    'Multiple Solutions found with given name',
  [errorTypes.SOLUTIONS_NOT_FOUND]: 'Solutions not found',
  [errorTypes.BUYER_SOLUTION_SAVED_SEARCH_NOT_FOUND]: 'Saved search not found',
  [errorTypes.BUYER_SOLUTION_SEARCH_NAME_ALREADY_EXISTS]:
    'Search name already exists',
  [errorTypes.SOLUTION_INCOMPLETE]: 'Solution incomplete',
  [errorTypes.SOLUTION_EXTERNAL_ID_ALREADY_EXISTS]:
    'Solution External ID already exists',
  [errorTypes.INVALID_RANGE_VALUE]: 'Invalid range value',
  [errorTypes.CANT_DELETE_SOLUTION_NOT_ARCHIVED]:
    "Can't delete solution not archived",
  [errorTypes.SOLUTION_WITHOUT_PUBLISHED_PRODUCTS_SERVICES]:
    'Solution without published products/services',
  [errorTypes.SOLUTION_MATCH_ALREADY_ACCEPTED]:
    'Solution match already accepted',
  [errorTypes.SOLUTION_MATCH_ALREADY_CANCELED]:
    'Solution match already canceled',
  [errorTypes.OPPORTUNITY_PURCHASE_TIMEFRAME_NOT_FOUND]:
    'Opportunity purchase timeframe not found',
  [errorTypes.OPPORTUNITY_EVALUATION_TIMEFRAME_NOT_FOUND]:
    'Opportunity evaluation timeframe not found',
  [errorTypes.OPPORTUNITY_ROLE_NOT_FOUND]: 'Opportunity role not found',
  [errorTypes.OPPORTUNITY_NOT_FOUND]: 'Opportunity not found',
  [errorTypes.OPPORTUNITY_ALREADY_EXISTS]: 'Match already exists',
  [errorTypes.CHAT_NOT_FOUND]: 'Chat not found',
  [errorTypes.CHAT_ALREADY_ARCHIVED]: 'Chat already archived',
  [errorTypes.CHAT_NOT_ARCHIVED]: 'Chat not archived',
  [errorTypes.CHAT_CHANNEL_NOT_FOUND]: 'Chat channel not found',
  [errorTypes.SOLUTION_ALREADY_FAVORITE]: 'Solution already favorite',
  [errorTypes.CANT_CHAT_WITH_YOURSELF]: "Can't start a chat with yourself",
  [errorTypes.LEAD_NOT_FOUND]: 'Match not found',
  [errorTypes.FAILED_CONNECT_CHAT]: 'Failed to connect to chat',
  [errorTypes.CANNOT_HIDE_LEAD_WITH_NOT_LOST_OR_DEAD_OPPORTUNITIES]:
    "You can't hide a match with not lost or dead opportunities",
  [errorTypes.OPPORTUNITY_CANNOT_BE_UPDATED]: 'Opportunity cannot be updated',
  [errorTypes.BUYER_BATCH_UPDATE_MAX_SIZE]: 'Maximum batch update limit is',
  [errorTypes.VENDOR_BATCH_UPDATE_MAX_SIZE]: 'Maximum batch update limit is',
  [errorTypes.ADMIN_BATCH_UPDATE_MAX_SIZE]: 'Maximum batch update limit is',
  [errorTypes.SOLUTION_BATCH_UPDATE_MAX_SIZE]: 'Maximum batch update limit is',
  [errorTypes.BUYER_BATCH_DELETE_MAX_SIZE]: 'Maximum batch delete limit is',
  [errorTypes.VENDOR_BATCH_DELETE_MAX_SIZE]: 'Maximum batch delete limit is',
  [errorTypes.ADMIN_BATCH_DELETE_MAX_SIZE]: 'Maximum batch delete limit is',
  [errorTypes.VERTICAL_EXPERTISE_BATCH_UPDATE_MAX_SIZE]:
    'Maximum batch update limit is',
  [errorTypes.VERTICAL_EXPERTISE_BATCH_DELETE_MAX_SIZE]:
    'Maximum batch delete limit is',
  [errorTypes.MAIN_CATEGORY_BATCH_UPDATE_MAX_SIZE]:
    'Maximum batch update limit is',
  [errorTypes.MAIN_CATEGORY_BATCH_DELETE_MAX_SIZE]:
    'Maximum batch delete limit is',
  [errorTypes.SUB_CATEGORY_BATCH_UPDATE_MAX_SIZE]:
    'Maximum batch update limit is',
  [errorTypes.SUB_CATEGORY_BATCH_DELETE_MAX_SIZE]:
    'Maximum batch delete limit is',
  [errorTypes.PRODUCT_SERVICE_BATCH_UPDATE_MAX_SIZE]:
    'Maximum batch update limit is',
  [errorTypes.PRODUCT_SERVICE_BATCH_DELETE_MAX_SIZE]:
    'Maximum batch delete limit is',
  [errorTypes.GEO_BATCH_UPDATE_MAX_SIZE]: 'Maximum batch update limit is',
  [errorTypes.GEO_BATCH_DELETE_MAX_SIZE]: 'Maximum batch delete limit is',
  [errorTypes.CHAT_BUYER_MISMATCH]:
    'Buyer userId in entry does not match current user for updating Buyer Company Chat Data',
  [errorTypes.CHAT_VENDOR_COMPANY_MISMATCH]:
    'Vendor companyId in entry does not match current company for updating Buyer Company Chat Data',
  [errorTypes.PLAN_NOT_FOUND]: 'Plan not found',
  [errorTypes.PUBLISHED_SOLUTIONS_LIMIT_REACHED]:
    'You reached the limit of published solutions included in your plan',
  [errorTypes.PUBLISHED_SOLUTIONS_LIMIT_REACHED_FOR_ADMINS]:
    'Tech vendor has reached their published solutions limit',
  [errorTypes.INVALID_SOLUTION_ID]: 'Invalid Solution ID',
  [errorTypes.INVALID_WHITEPAPER_ID]: 'Invalid White Paper ID',
  [errorTypes.INVALID_PRODUCT_SERVICE_ID]: 'Invalid Product/Service ID',
  [errorTypes.SUBSCRIPTION_NOT_FOUND]:
    'You are not subscribed to a plan, please subscribe to continue',
  [errorTypes.PENDING_INVOICES]:
    'You cannot subscribe to a plan if you have pending invoices.',
  [errorTypes.INVOICE_NOT_FOUND]: 'Invoice not found',
  [errorTypes.INVOICE_PAYMENT_INTENT_REQUIRES_ACTION]:
    'Payment requires action',
  [errorTypes.STRIPE_ID_NOT_FOUND]:
    'Could not find your payment gateway identification, please login again',
  [errorTypes.INVOICE_PDF_GENERATION]: 'Could not generate the invoice PDF',
  [errorTypes.PAYMENT_PLAN_PRICING_NOT_FOUND]: 'Payment plan pricing not found',
  [errorTypes.SUPPORT_HOURS_PRICE_NOT_FOUND]: 'Support hours price not found',
  [errorTypes.PRICES_NOT_FOUND]: 'Prices not found',
  [errorTypes.MISSING_AT_LEAST_ONE_PLAN_AVAILABLE]:
    'At least one plan should be available',
  [errorTypes.HIGHLIGHTED_PLAN_NOT_AVAILABLE]: 'Highlighted plan not available',
  [errorTypes.ALREADY_SUBSCRIBED_TO_A_PLAN]:
    'You are already subscribed to a plan. Please cancel your current subscription to subscribe to a new plan.',
  [errorTypes.PLAN_NOT_AVAILABLE]:
    'This plan is not available for subscription',
  [errorTypes.SUPPORT_HOUR_NOT_FOUND]: 'Support hour not found',
  [errorTypes.CANNOT_APPROVE_SUPPORT_HOUR]:
    'You cannot approve this support hour',
  [errorTypes.CANNOT_REJECT_SUPPORT_HOUR]:
    'You cannot reject this support hour',
  [errorTypes.CANNOT_DELETE_SUPPORT_HOUR]:
    'You are not allowed to delete this support hour',
  [errorTypes.CANNOT_UPDATE_SUPPORT_HOUR_RATE]:
    'You are not allowed to update the rate',
  [errorTypes.EMAIL_CANNOT_BE_SAME_AS_PERSONAL_EMAIL]:
    'Personal email can’t be the same as the work email',
  [errorTypes.CANNOT_PERFORM_ACTION_ON_SUPPORT_HOUR]:
    'You are not allowed to perform this action on this support hour',
  [errorTypes.VENDOR_SUBSCRIPTION_BLOCKING]: 'Vendor subscription blocking',
  [errorTypes.NO_CSV_DATA]: "There's no data to export",
  [errorTypes.FEATURE_NOT_AVAILABLE]: 'This feature is not available',
  [errorTypes.INVALID_PLAN_ID]: 'Invalid plan ID',
  [errorTypes.INVALID_COUPON_ID]: 'Invalid coupon ID',
  [errorTypes.INVALID_COUPON_DURATION]: 'Invalid coupon duration',
  [errorTypes.COUPON_NOT_FOUND]: 'Coupon not found',
  [errorTypes.BLOG_WITH_TITLE_ALREADY_EXISTS]:
    'A Blog post with that title already exists. Please choose another.',
  [errorTypes.WHITEPAPER_WITH_TITLE_ALREADY_EXISTS]:
    'A White Paper with that title already exists. Please choose another.',
  [errorTypes.INVALID_PRESS_POST_ID]: 'Invalid press post ID',
  [errorTypes.INVALID_BLOG_POST_ID]: 'Invalid blog post ID',
  [errorTypes.INVALID_LEAD_ID]: 'Invalid lead ID',
  [errorTypes.INVALID_SUBSCRIPTION_ID]: 'Invalid subscription ID',
  [errorTypes.BUYER_INVALID_CHATBOT_CONVERSATION_ID]:
    'Invalid Cosmo conversation ID',
  [errorTypes.BUYER_INVALID_CHATBOT_MESSAGE_ID]: 'Invalid Cosmo message ID',
  [errorTypes.BUYER_CHATBOT_CONVERSATION_NOT_FOUND]:
    'Cosmo conversation not found',
  [errorTypes.BUYER_CHATBOT_MESSAGE_ALREADY_REPORTED]:
    'Cosmo message already reported',
  [errorTypes.BLOG_POST_INCOMPLETE]: 'Blog Post Incomplete',
  [errorTypes.PRESS_POST_INCOMPLETE]: 'Press Post Incomplete',
  [errorTypes.WHITEPAPER_INCOMPLETE]: 'White Paper Incomplete',
  [errorTypes.VENDOR_CAMPAIGN_INCOMPLETE]: 'Vendor Campaign Incomplete',
  [errorTypes.PROMOTION_CODE_NOT_FOUND]: 'Promotion code not found',
  [errorTypes.PROMOTION_CODE_EXPIRED]: 'Promotion code expired',
  [errorTypes.PROMOTION_CODE_INACTIVE]: 'Promotion code inactive',
  [errorTypes.CALENDAR_EVENT_NOT_FOUND]: 'Meeting not found',
  [errorTypes.MAX_ONE_YEAR_IN_FUTURE]: 'Maximum one year in the future',
  [errorTypes.CALENDAR_SLOT_UNAVAILABLE]: 'Time not available',
  [errorTypes.PAST_DATE_NOT_ALLOWED]: 'Past dates not allowed',
  [errorTypes.INVALID_CALENDAR_EVENT_CALL_TYPE]: 'Invalid meeting call type',
  [errorTypes.VENDOR_CALENDAR_NO_LONGER_CONNECTED]:
    'Vendor calendar no longer connected',
  [errorTypes.MAX_SOLUTIONS]: 'Max Solutions is:',
  [errorTypes.INVALID_OFFER_ID]: 'Invalid referral offer ID',
  [errorTypes.INVALID_PARTNER_ID]: 'Invalid partner ID',
  [errorTypes.OFFER_NOT_FOUND]: 'The referral offer was not found',
  [errorTypes.OFFER_NOT_ACTIVE]: 'The referral offer is not active',
  [errorTypes.PARTNER_NOT_FOUND]: 'The partner was not found',
  [errorTypes.PARTNER_NOT_ACTIVE]: 'The partner is not active',
  [errorTypes.STRIPE_CUSTOMER_NOT_FOUND]:
    'The customer payment data was not found. Please, sign in again',
  [errorTypes.MAX_STANDARD_PLANS_REACHED]:
    'Max standard plans reached. Please make sure to update an available plan to hidden',
  [errorTypes.INVALID_SUPPORT_HOUR_ID]: 'Invalid Support Hour ID',
  [errorTypes.VENDOR_CAMPAIGN_NOT_FOUND]: 'Campaign not found',
  [errorTypes.INVALID_VENDOR_CAMPAIGN_ID]: 'Invalid campaign ID',
  [errorTypes.FINANCE_CONTACT_CANNOT_BE_REMOVED]:
    'Finance contact cannot be removed, you need to add another finance contact instead',
  [paymentErrorTypes.INVALID_VALUE]: 'Invalid value',
  [errorTypes.CATEGORY_V2_NOT_FOUND]: 'Category not found',
  [errorTypes.CATEGORY_FEATURE_V2_NOT_FOUND]: 'Category feature not found',
  [errorTypes.CATEGORY_FILTER_V2_NOT_FOUND]: 'Category filter not found',
  [errorTypes.INVALID_CATEGORY_FEATURE_V2_ID]: 'Invalid category feature ID',
  [errorTypes.INVALID_CATEGORY_FILTER_V2_ID]: 'Invalid category filter ID',
  [errorTypes.INVALID_CATEGORY_V2_ID]: 'Invalid category ID',
  [errorTypes.INVALID_GEO_ID]: 'Invalid geo ID',
  [errorTypes.INCENTIVE_NOT_FOUND]: 'Incentive not found',
  [errorTypes.DEFAULT]: 'Something went wrong',
} as const

export const isCustomExceptionThrown = ({ exception }: { exception: any }) =>
  Array.isArray(exception) && exception[0][3]?.includes('errors.')

export const handleServiceError = (err: any) => {
  if (isCustomExceptionThrown({ exception: err })) {
    throw err
  }

  const apiErrorMessages = err?.response?.data.message
  const values = err?.response?.data.values

  const isCustomError =
    apiErrorMessages &&
    Array.isArray(apiErrorMessages) &&
    apiErrorMessages[0].includes('errors.')

  if (isCustomError) {
    const mappedErrorMessages = Array.from<string>(apiErrorMessages).map(
      (message: string) => {
        const splittedMessage = message.split('.')
        const errorTypeIndex = splittedMessage.findIndex((v) => v === 'errors')

        const type = splittedMessage[errorTypeIndex]
        const field = splittedMessage[errorTypeIndex + 1]
        const error = splittedMessage[errorTypeIndex + 2]
        const value = splittedMessage[errorTypeIndex + 3]

        if (type !== 'errors') {
          return undefined
        }

        const errorType = `${type}.${error}`

        const errorMessage =
          errorMessages[errorType as keyof typeof errorMessages]
        const newField = field === '_' ? undefined : field

        return [errorMessage, newField, value, errorType, values]
      }
    )

    return mappedErrorMessages.filter((v) => v !== undefined)
  }

  return new Error(err)
}
