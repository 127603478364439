import {
  AdminAction,
  AdminRole,
  BeeAction,
  BrandBatchAction,
  GeoBatchAction,
  IAdmin,
  IncentiveBatchAction,
  MainCategoryBatchAction,
  ProductServiceBatchAction,
  SolutionBatchAction,
  SubCategoryBatchAction,
  TeamMemberBatchAction,
  UserBatchAction,
  VerticalExpertiseBatchAction,
} from '@utils/types'

interface IIsAdminAllowed {
  role: AdminRole
  action: AdminAction
}

type IsAdminBatchActionAllowed = {
  role?: AdminRole
} & (
  | { entity: 'user'; batchAction: UserBatchAction }
  | { entity: 'productService'; batchAction: ProductServiceBatchAction }
  | { entity: 'solution'; batchAction: SolutionBatchAction }
  | { entity: 'brand'; batchAction: BrandBatchAction }
  | { entity: 'geo'; batchAction: GeoBatchAction }
  | {
      entity: 'verticalExpertise'
      batchAction: VerticalExpertiseBatchAction
    }
  | { entity: 'mainCategory'; batchAction: MainCategoryBatchAction }
  | { entity: 'subCategory'; batchAction: SubCategoryBatchAction }
  | { entity: 'teamMember'; batchAction: TeamMemberBatchAction }
  | { entity: 'incentive'; batchAction: IncentiveBatchAction }
)

interface IGetRoleText {
  role?: AdminRole
}

const isAdminAllowed = ({ role, action }: IIsAdminAllowed): boolean => {
  const allowedActionsPerRole: Record<AdminRole, AdminAction[]> = {
    [AdminRole.SUPPORT]: [AdminAction.READ, AdminAction.IMPERSONATE],
    [AdminRole.FINANCE]: [
      AdminAction.READ,
      AdminAction.IMPERSONATE,
      AdminAction.MANAGE_SUPPORT_HOURS,
    ],
    [AdminRole.SALES]: [
      AdminAction.READ,
      AdminAction.CREATE,
      AdminAction.EDIT,
      AdminAction.IMPERSONATE,
      AdminAction.MANAGE_ENTERPRISE_PLANS,
    ],
    [AdminRole.ADMIN]: [
      AdminAction.READ,
      AdminAction.CREATE,
      AdminAction.EDIT,
      AdminAction.IMPERSONATE,
      AdminAction.ARCHIVE_ALL_ASSISTANCE_CHATS,
      AdminAction.MANAGE_PRESS_OR_BLOG_POSTS,
      AdminAction.MANAGE_PUBLIC_WHITEPAPERS,
      AdminAction.MANAGE_COUPONS,
      AdminAction.MANAGE_ENTERPRISE_PLANS,
      AdminAction.MANAGE_INCENTIVES,
    ],
    [AdminRole.SUPER_ADMIN]: [
      AdminAction.READ,
      AdminAction.CREATE,
      AdminAction.DELETE,
      AdminAction.EDIT,
      AdminAction.IMPERSONATE,
      AdminAction.CREATE_AND_EDIT_TEAM_MEMBERS,
      AdminAction.MANAGE_PLANS_SETTINGS,
      AdminAction.ARCHIVE_ALL_ASSISTANCE_CHATS,
      AdminAction.MANAGE_SUPPORT_HOURS,
      AdminAction.IMPORT_SOLUTIONS,
      AdminAction.IMPORT_OPPORTUNITIES,
      AdminAction.MANAGE_PRESS_OR_BLOG_POSTS,
      AdminAction.MANAGE_PUBLIC_WHITEPAPERS,
      AdminAction.MANAGE_COUPONS,
      AdminAction.MANAGE_ENTERPRISE_PLANS,
      AdminAction.MANAGE_INCENTIVES,
    ],
  }

  return allowedActionsPerRole[role]?.includes(action) ?? false
}

const ALLOWED_USER_BATCH_ACTION_PER_ADMIN_ROLE: Record<AdminRole, string[]> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [UserBatchAction.ACTIVATE, UserBatchAction.DEACTIVATE],
  [AdminRole.SUPER_ADMIN]: [
    UserBatchAction.ACTIVATE,
    UserBatchAction.DEACTIVATE,
    UserBatchAction.RECOVER,
    UserBatchAction.DELETE,
  ],
}

const ALLOWED_PRODUCT_SERVICE_BATCH_ACTION_PER_ADMIN_ROLE: Record<
  AdminRole,
  string[]
> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [
    ProductServiceBatchAction.PUBLISH,
    ProductServiceBatchAction.ARCHIVE,
    ProductServiceBatchAction.UNARCHIVE,
    ProductServiceBatchAction.TURN_INTO_DRAFT,
  ],
  [AdminRole.SUPER_ADMIN]: [
    ProductServiceBatchAction.PUBLISH,
    ProductServiceBatchAction.ARCHIVE,
    ProductServiceBatchAction.UNARCHIVE,
    ProductServiceBatchAction.TURN_INTO_DRAFT,
    ProductServiceBatchAction.DELETE,
  ],
}

const ALLOWED_SOLUTION_BATCH_ACTION_PER_ADMIN_ROLE: Record<
  AdminRole,
  string[]
> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [
    SolutionBatchAction.PUBLISH,
    SolutionBatchAction.UNPUBLISH,
    SolutionBatchAction.ARCHIVE,
    SolutionBatchAction.UNARCHIVE,
  ],
  [AdminRole.SUPER_ADMIN]: [
    SolutionBatchAction.PUBLISH,
    SolutionBatchAction.UNPUBLISH,
    SolutionBatchAction.ARCHIVE,
    SolutionBatchAction.UNARCHIVE,
    SolutionBatchAction.DELETE,
  ],
}

const ALLOWED_MAIN_CATEGORY_BATCH_ACTION_PER_ADMIN_ROLE: Record<
  AdminRole,
  string[]
> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [
    MainCategoryBatchAction.ACTIVATE,
    MainCategoryBatchAction.DEACTIVATE,
  ],
  [AdminRole.SUPER_ADMIN]: [
    MainCategoryBatchAction.ACTIVATE,
    MainCategoryBatchAction.DEACTIVATE,
    MainCategoryBatchAction.DELETE,
  ],
}

const ALLOWED_GEOS_BATCH_ACTION_PER_ADMIN_ROLE: Record<AdminRole, string[]> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [GeoBatchAction.ACTIVATE, GeoBatchAction.DEACTIVATE],
  [AdminRole.SUPER_ADMIN]: [
    GeoBatchAction.ACTIVATE,
    GeoBatchAction.DEACTIVATE,
    GeoBatchAction.DELETE,
  ],
}

const ALLOWED_SUB_CATEGORY_BATCH_ACTION_PER_ADMIN_ROLE: Record<
  AdminRole,
  string[]
> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [
    SubCategoryBatchAction.ACTIVATE,
    SubCategoryBatchAction.DEACTIVATE,
  ],
  [AdminRole.SUPER_ADMIN]: [
    SubCategoryBatchAction.ACTIVATE,
    SubCategoryBatchAction.DEACTIVATE,
    SubCategoryBatchAction.DELETE,
  ],
}

const ALLOWED_VERTICAL_EXPERTISE_BATCH_ACTION_PER_ADMIN_ROLE: Record<
  AdminRole,
  string[]
> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [
    VerticalExpertiseBatchAction.ACTIVATE,
    VerticalExpertiseBatchAction.DEACTIVATE,
  ],
  [AdminRole.SUPER_ADMIN]: [
    VerticalExpertiseBatchAction.ACTIVATE,
    VerticalExpertiseBatchAction.DEACTIVATE,
    VerticalExpertiseBatchAction.DELETE,
  ],
}

const ALLOWED_BRAND_BATCH_ACTION_PER_ADMIN_ROLE: Record<AdminRole, string[]> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [BrandBatchAction.ACTIVATE, BrandBatchAction.DEACTIVATE],
  [AdminRole.SUPER_ADMIN]: [
    BrandBatchAction.ACTIVATE,
    BrandBatchAction.DEACTIVATE,
    BrandBatchAction.DELETE,
  ],
}

const ALLOWED_TEAM_MEMBER_BATCH_ACTION_PER_ADMIN_ROLE: Record<
  AdminRole,
  string[]
> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [],
  [AdminRole.SUPER_ADMIN]: [
    TeamMemberBatchAction.ACTIVATE,
    TeamMemberBatchAction.DEACTIVATE,
    TeamMemberBatchAction.DELETE,
    TeamMemberBatchAction.RECOVER,
  ],
}

const ALLOWED_INCENTIVE_BATCH_ACTION_PER_ADMIN_ROLE: Record<
  AdminRole,
  string[]
> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [
    IncentiveBatchAction.APPROVE,
    IncentiveBatchAction.CANCEL,
  ],
  [AdminRole.SUPER_ADMIN]: [
    IncentiveBatchAction.APPROVE,
    IncentiveBatchAction.CANCEL,
  ],
}

const ALLOWED_ENTITY_BATCH_ACTION_PER_ADMIN = {
  user: ALLOWED_USER_BATCH_ACTION_PER_ADMIN_ROLE,
  productService: ALLOWED_PRODUCT_SERVICE_BATCH_ACTION_PER_ADMIN_ROLE,
  mainCategory: ALLOWED_MAIN_CATEGORY_BATCH_ACTION_PER_ADMIN_ROLE,
  geo: ALLOWED_GEOS_BATCH_ACTION_PER_ADMIN_ROLE,
  subCategory: ALLOWED_SUB_CATEGORY_BATCH_ACTION_PER_ADMIN_ROLE,
  verticalExpertise: ALLOWED_VERTICAL_EXPERTISE_BATCH_ACTION_PER_ADMIN_ROLE,
  brand: ALLOWED_BRAND_BATCH_ACTION_PER_ADMIN_ROLE,
  solution: ALLOWED_SOLUTION_BATCH_ACTION_PER_ADMIN_ROLE,
  teamMember: ALLOWED_TEAM_MEMBER_BATCH_ACTION_PER_ADMIN_ROLE,
  incentive: ALLOWED_INCENTIVE_BATCH_ACTION_PER_ADMIN_ROLE,
} as const

const isAdminEntityBatchActionAllowed = ({
  role,
  entity,
  batchAction,
}: IsAdminBatchActionAllowed): boolean => {
  if (!role) {
    return false
  }

  return ALLOWED_ENTITY_BATCH_ACTION_PER_ADMIN[entity][role].includes(
    batchAction
  )
}

type IsAdminBeeActionAllowed = {
  role?: AdminRole
} & { component: 'fileManager'; action: BeeAction }

const ALLOWED_BEE_FILE_MANAGER_ACTION_PER_ADMIN_ROLE: Record<
  AdminRole,
  BeeAction[]
> = {
  [AdminRole.SUPPORT]: [],
  [AdminRole.FINANCE]: [],
  [AdminRole.SALES]: [],
  [AdminRole.ADMIN]: [],
  [AdminRole.SUPER_ADMIN]: [BeeAction.DELETE],
}

const ALLOWED_BEE_ACTION_PER_ADMIN = {
  fileManager: ALLOWED_BEE_FILE_MANAGER_ACTION_PER_ADMIN_ROLE,
} as const

const isAdminBeeActionAllowed = ({
  role,
  component,
  action,
}: IsAdminBeeActionAllowed): boolean => {
  if (!role) {
    return false
  }

  return ALLOWED_BEE_ACTION_PER_ADMIN[component][role].includes(action)
}

const getAdminRoleText = ({ role }: IGetRoleText) => {
  if (!role) {
    return undefined
  }

  const roleText: Record<AdminRole, string> = {
    [AdminRole.SUPPORT]: 'Support',
    [AdminRole.FINANCE]: 'Finance',
    [AdminRole.SALES]: 'Sales',
    [AdminRole.ADMIN]: 'Admin',
    [AdminRole.SUPER_ADMIN]: 'Super admin',
  }

  return roleText[role]
}

const buildCommonBatchActions = ({
  adminRole,
  selectedRows,
  rowProperty,
  batchActionsMapping,
}: {
  adminRole?: AdminRole
  selectedRows?: any[]
  rowProperty: string
  batchActionsMapping: Record<string, string[]>
}) => {
  if (
    !selectedRows?.length ||
    adminRole === AdminRole.SUPPORT ||
    adminRole === AdminRole.FINANCE ||
    adminRole === AdminRole.SALES
  ) {
    return []
  }

  const selectedRowsStates = selectedRows?.reduce(
    (acc, row) => [...acc, String(row[rowProperty])],
    [] as string[]
  )

  const selectedRowsStatesSet = new Set(selectedRowsStates)

  const selectedActions = Object.entries(batchActionsMapping)
    .filter(([entry]) => selectedRowsStatesSet.has(entry))
    .map((v) => v[1])

  if (!selectedActions.length) {
    return []
  }

  const selectedCommonActions = selectedActions.reduce(
    (acc, curr, index) => {
      if (index === 0) {
        return acc
      }

      return acc.filter((v) => curr.includes(v))
    },
    [...selectedActions[0]]
  )

  return selectedCommonActions
}

export const orderCurrentAdminAsFirstOption = ({
  currentAdmin,
  admins,
}: {
  currentAdmin?: IAdmin
  admins: IAdmin[]
}) =>
  [...admins].sort((a, b) => {
    if (a.userId === currentAdmin?.userId) {
      return -1
    }
    if (b.userId === currentAdmin?.userId) {
      return 1
    }
    return 0
  })

const exports = {
  isAdminAllowed,
  isAdminEntityBatchActionAllowed,
  isAdminBeeActionAllowed,
  getAdminRoleText,
  buildCommonBatchActions,
}

export default exports
